import React, { useEffect, useState, useCallback, useContext } from "react";
import SEO from "components/seo";
import Layout from "components/layout";
import { Root, Content, ImageWrapper, Image, FormContent, FormContainer } from "./purchase";
import { Router, navigate } from "@reach/router";
import api from "services/api";
import StripeForm from "components/Payments/stripe-form";
import { Elements, StripeProvider } from "react-stripe-elements";
import { PurchaseTile } from "components/Payments/purchase-tile";
import styled from "styled-components";
import { SplashScreen } from "components/splash-screen";
import { ContentContext } from "hooks/use-content";

const Section = styled.div`
    margin-bottom: 65px;
`;

const Heading = styled.div`
    font-size: 24px;
`;

function PurchaseSection(props: any & { purchaseId: string }) {
    const [purchase, setPurchase] = useState<Api.PurchaseDTO | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const content = useContext(ContentContext).payment;

    useEffect(() => {
        const fetchPurchase = async () => {
            setIsLoading(true);

            const result = await api.purchaseById({
                PurchaseId: props.purchaseId,
            });

            if (result.isSuccess) {
                setPurchase(result.result);
                setIsLoading(false);
            }
        };

        fetchPurchase();
    }, [props.purchaseId]);

    const onPaymentRequestFinish = useCallback(
        (wasSuccessful: boolean) => {
            setIsLoading(false);

            if (wasSuccessful) {
                navigate(`/confirm-purchase/${props.purchaseId}`);
            }
        },
        [setIsLoading, props.purchaseId],
    );

    return (
        <FormContent>
            {isLoading && <SplashScreen />}
            {purchase && purchase.PaymentToken && purchase.Price && (
                <>
                    <Section>
                        <PurchaseTile
                            teamLogo={purchase.TeamPhoto}
                            teamName={purchase.TeamName}
                            price={purchase.Price}
                        />
                    </Section>
                    <Section>
                        <StripeProvider apiKey={purchase.PaymentToken.PublishableKey}>
                            <Elements>
                                <StripeForm
                                    clientSecret={purchase.PaymentToken.CustomerSecret}
                                    onPaymentRequestFinish={onPaymentRequestFinish}
                                    onStartPayment={() => setIsLoading(true)}
                                />
                            </Elements>
                        </StripeProvider>
                    </Section>
                    <Section>
                        <Heading>{content.contact}</Heading>
                    </Section>
                </>
            )}
        </FormContent>
    );
}

export default function Payment() {
    return (
        <Layout>
            <SEO title="Purchase" />
            <Root>
                <Content>
                    <ImageWrapper>
                        <Image />
                    </ImageWrapper>
                    <FormContainer>
                        <Router>
                            <PurchaseSection path="/payment/:purchaseId" />
                        </Router>
                    </FormContainer>
                </Content>
            </Root>
        </Layout>
    );
}
