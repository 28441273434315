import React, { useCallback, useState, useContext } from "react";
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement, CardElement } from "react-stripe-elements";
import styled from "styled-components";
import { StripeFormElement } from "./stripe-form-element";
import iconCreditCards from "images/payments/credit-cards.png";
import { ErrorMessage } from "components/error-message";
import { ContentContext } from "hooks/use-content";
import { Button } from "components/button";

const stripeElementStyle = {
    base: {
        background: "none",
        border: "none",
        lineHeight: "1rem",
        fontSize: "14px",
        "::placeholder": {
            color: "#a3adba",
        },
    },
};

const CardDetails = styled.div`
    display: flex;
`;

const StripeFormRowElement = styled(StripeFormElement)`
    flex: 1 0 auto;
    margin-right: 16px;
    margin-top: 0;

    :last-of-type {
        margin-right: 0;
    }
`;

const Header = styled.div`
    font-size: 24px;
    margin-bottom: 16px;
`;

type StripeFormProps = {
    stripe?: stripe.Stripe;
    clientSecret: string;

    onPaymentRequestFinish: (wasSuccessful: boolean) => void;
    onStartPayment: () => void;
};

const StripeForm = (props: StripeFormProps) => {
    const [validationError, setValidationError] = useState<string | undefined>();
    const content = useContext(ContentContext).payment;

    const handleSubmit = useCallback(
        async (ev: React.FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            if (!props.stripe) {
                return;
            }

            props.onStartPayment();

            const result = await props.stripe.handleCardPayment(props.clientSecret);

            if (!result.error) {
                props.onPaymentRequestFinish(true);
            } else {
                switch (result.error.type) {
                    case "card_error":
                        setValidationError(content.stripeForm.errors.card);
                        break;
                    default:
                        setValidationError(content.stripeForm.errors.general);
                }

                props.onPaymentRequestFinish(false);
            }
        },
        [props, content],
    );

    return (
        <form onSubmit={handleSubmit}>
            <Header>{content.stripeForm.header}</Header>
            <img src={iconCreditCards} />
            <StripeFormElement label={content.stripeForm.cardNumberLabel}>
                <CardNumberElement style={stripeElementStyle} placeholder="0000 0000 0000 0000" />
            </StripeFormElement>
            <CardDetails>
                <StripeFormRowElement label={content.stripeForm.expirationDateLabel}>
                    <CardExpiryElement style={stripeElementStyle} />
                </StripeFormRowElement>
                <StripeFormRowElement label={content.stripeForm.securityCodeLabel}>
                    <CardCVCElement style={stripeElementStyle} />
                </StripeFormRowElement>
            </CardDetails>
            {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
            <Button>{content.stripeForm.buttonLabel}</Button>
        </form>
    );
};

export default injectStripe(StripeForm);
