import { Root, Label, InputContainer } from "components/field";
import React from "react";
import styled from "styled-components";

type StripeFormElementProps = {
    label: string;

    className?: string;
};

const StyledInputContainer = styled(InputContainer)`
    * {
        flex: 1 1 0;
    }
`;

export const StripeFormElement: React.FunctionComponent<StripeFormElementProps> = props => {
    return (
        <Root className={props.className}>
            <Label>{props.label}</Label>
            <StyledInputContainer valid>{props.children}</StyledInputContainer>
        </Root>
    );
};
