import React, { useContext } from "react";
import styled from "styled-components";
import themeColor from "styles/theme-color";
import iconTick from "images/payments/icon-tick.svg";
import { ContentContext } from "hooks/use-content";

type PurchaseTileProps = {
    teamName: string;
    teamLogo: string;

    price: Api.PriceDTO;
};

export const PurchaseTile: React.FunctionComponent<PurchaseTileProps> = props => {
    const content = useContext(ContentContext).payment;

    return (
        <Root>
            <TeamDetails>
                <TeamLogo src={props.teamLogo} />
                <TeamName>{props.teamName}</TeamName>
            </TeamDetails>
            <DescriptionContainer>
                <IconTick src={iconTick} />
                <Description>{content.starterPackText}</Description>
            </DescriptionContainer>
            <PriceContainer>
                <Price>
                    {props.price.AmountInSmallestUnit / 100} {props.price.Currency}
                </Price>
            </PriceContainer>
        </Root>
    );
};

const PriceContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Price = styled.div`
    color: ${themeColor("blue")};
    font-size: 45px;
    margin-top: 25px;
    flex: 0 0 auto;
`;

const IconTick = styled.img`
    width: 24px;
    height: 24px;
    flex: 0 1 auto;
    margin-right: 20px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
`;

const Description = styled.div`
    font-size: 16px;
    color: ${themeColor("fontSemiDark")};
`;

const Root = styled.div`
    background-color: ${themeColor("backgroundLight")};
    border-radius: 14px;
    padding: 15px 25px;
`;

const TeamLogo = styled.img`
    border-radius: 10px;
    max-height: 100px;
    max-width: 100px;
    object-fit: contain;
    object-position: 50% 50%;
    box-shadow: 0 1px 0 0 rgba(163, 173, 186, 0.26), 0 16px 24px 0 rgba(163, 173, 186, 0.05);
    background: white;
`;

const TeamName = styled.div`
    color: ${themeColor("fontDark")};
    font-size: 24px;
    font-weight: bold;
    margin-left: 20px;
`;

const TeamDetails = styled.div`
    display: flex;
    margin-bottom: 25px;
    align-items: center;
`;
